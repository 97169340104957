import React,{ useState,useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Button, Table,Popover,Select,Form,Input,Col, DatePicker, Row } from 'antd';
import type { TableColumnsType } from 'antd';
import Moment from 'moment';
import {httpRequest} from "../../../services/axiosService"

import {
  LeftSquareOutlined,
  RightSquareOutlined,
  SearchOutlined,
  DownloadOutlined,
  SyncOutlined
} from '@ant-design/icons';
export interface process {
  [key: string]: string | undefined
}
interface DataType {
  key: string;
  id: string;
  email:string;
  keepInTouch: any;
  tailoredPage: string;
  language:any;
  createdAt:any;
}
const columns: TableColumnsType<DataType> = [
  {
    title: 'Id',
    dataIndex: 'contactId',
    key: 'contactId',
    width: "200px",
    fixed: 'left',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fixed: 'left',
  },
  {
    title: 'keepInTouch',
    dataIndex: 'keepInTouch',
    key: 'keepInTouch',
    render: (obj) => {
      return <>{obj?"True":"False"}</>
    },
  },
  {
    title: 'Page',
    dataIndex: 'tailoredPage',
    key: 'tailoredPage',
  },
  {
    title: 'Language',
    dataIndex: 'language',
    key: 'language',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (obj) => {
      return <>{Moment(obj).format('DD/MM/YYYY HH:mm:ss')}</>
    },
  }
];
const { RangePicker } = DatePicker;
const pageLimit=10
const Index = () => { 
  const [data, setData] = useState<any>([]);
  const [exclusiveStartKey, setExclusiveStartKey] = useState<any>([]);
  const [page, setPage] = useState(-1);
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  form.setFieldsValue({reportType:"go-365"})
  useEffect(() => {
    (async () => {
      form.setFieldsValue({ limit: pageLimit})
      form.setFieldsValue({action:"table"})
      let results = await getContent()
      setData([...results['Items']])
      await lastEvaluatedKey(results['LastEvaluatedKey'])
    })();
  }, [])
  const getContent = async()=>{
    setLoader(true)
    let results = await httpRequest.post(
      process.env.REACT_APP_GET_SHARE_SCORE_DEP??"",
      form.getFieldsValue(true)
    )
    setLoader(false)
    return results['body']
  }
  const lastEvaluatedKey=async(lastEvaluatedKey:any)=>{
    if(lastEvaluatedKey){
      form.setFieldsValue({exclusiveStartKey:lastEvaluatedKey})
      const index = exclusiveStartKey.findIndex((item:any) => {
        return item.id == lastEvaluatedKey.id && item.tailoredPage==lastEvaluatedKey.tailoredPage
      })
      if(index===-1){
        setExclusiveStartKey(exclusiveStartKey.concat(lastEvaluatedKey))
        return true
      }
    }else{
      form.setFieldsValue({exclusiveStartKey:""})
    }
  }
  const onNext=async()=>{
    if(form.getFieldValue("exclusiveStartKey")){
      form.setFieldsValue({action:"table"})
      let results = await getContent();
      setData([...results['Items']]);
      await lastEvaluatedKey(results['LastEvaluatedKey'])
      setPage(page+1)
    }else{
      console.log(page)
      console.log("No next page")
    }
  }
  const onPrevious=async()=>{
    if (page<0) {
      console.log("No previous pages")
      return false
    }
    if(exclusiveStartKey[page-1]){
      form.setFieldsValue({exclusiveStartKey:exclusiveStartKey[page-1]})
    }else{
      form.setFieldsValue({exclusiveStartKey:""})
    }
    form.setFieldsValue({action:"table"})
    let results = await getContent();
    setData([...results['Items']]);
    await lastEvaluatedKey(results['LastEvaluatedKey'])
    setPage(page-1)
  }
  const onSearch = async() => {
    if(!form.getFieldsValue(true).filterData) return false
    
    setPage(-1)
    setExclusiveStartKey([...[]])
    form.setFieldsValue({action:"table"})
    form.setFieldsValue({exclusiveStartKey:""})
    //form.setFieldsValue({limit:""})
    let results = await getContent()
    setData([...results['Items']])
    await lastEvaluatedKey(results['LastEvaluatedKey'])
  }
  const onReset = async() => {
    form.resetFields();
    setPage(-1)
    setExclusiveStartKey([...[]])
    form.setFieldsValue({action:"table"})
    form.setFieldsValue({exclusiveStartKey:""})
    form.setFieldsValue({ limit: pageLimit})
    let results = await getContent()
    setData([...results['Items']])
    await lastEvaluatedKey(results['LastEvaluatedKey'])
  };
  const onDownload = async()=>{
    form.setFieldsValue({limit:""})
    form.setFieldsValue({action:"excel"})
    let results = await getContent()
    saveAs.saveAs(results, `${Date.now()}_report.xlsx`)
  }
  const onSelectRagePicker = (date:any)=>{
    if(Array.isArray(date)){
      form.setFieldsValue({['filterData']: 
        { "rangePicker":[date[0].format('MM-DD-YYYY HH:mm:ss'), date[1].format('MM-DD-YYYY HH:mm:ss')]}
      })
    }else{
      form.setFieldsValue({['filterData']: 
        { "rangePicker":[]}
      })
    }
  }
  const onSelectPageLimit = async(limit:any)=>{
    //if(form.getFieldsValue(true).filterData) return false
    
    setPage(-1)
    setExclusiveStartKey([...[]])
    form.setFieldsValue({action:"table"})
    form.setFieldsValue({exclusiveStartKey:""})
    form.setFieldsValue({limit:limit})
    let results = await getContent()
    setData([...results['Items']])
    await lastEvaluatedKey(results['LastEvaluatedKey'])
  }
  return (
    <>
    <Form disabled={loader} onFinish={onSearch} form={form}>
      <Form.List name="filterData">
        {(fields) => (
          <Row gutter={24}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="email">
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="contactId">
                <Input placeholder="Id" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="language">
                <Select
                  placeholder="Languange"
                  style={{ width: "100%" }}
                  options={[
                    { value: 'en', label: 'English' },
                    { value: 'es', label: 'Spanish' }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="keepInTouch">
                <Select
                  placeholder="KeepInTouch"
                  style={{ width: "100%" }}
                  options={[
                    { value: 'true', label: 'True' },
                    { value: 'false', label: 'False' }
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form.List>
      <Row gutter={24}>
        <Col className="gutter-row" span={8}>
          <Form.Item name="rangePicker">
            <RangePicker
              onChange={onSelectRagePicker}
              style={{width:"100%"}}
              showTime={{ format: 'HH:mm' }}
              format="DD/MM/YYYY HH:mm"
              />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={4}>
          &nbsp;
        </Col>
        <Col className="gutter-row" span={4}>
          <Button type="primary" ghost style={{width:"100%"}} icon={<SyncOutlined />} onClick={onReset}>Reset</Button>
        </Col>
        <Col style={{display: 'flex', justifyContent: 'flex-end'}} className="gutter-row" span={4}>
          <Button type="primary" ghost style={{width:"100%"}} icon={<SearchOutlined />}  htmlType="submit">Search</Button>
        </Col>
        <Col className="gutter-row" span={4}>
          <Button type="primary" ghost style={{width:"100%"}} icon={<DownloadOutlined />} onClick={onDownload}>Download</Button>
        </Col>
      </Row>
    </Form>
    <Table columns={columns} 
      dataSource={data} 
      pagination={false}
      loading={loader}
      rowKey={(record:any,key:any) => {
        return key
      }}
      scroll={{ x: 1500, y: 300 }}
      footer={(currentPageData) => {
        // return  <Button onClick={next} disabled={form.getFieldValue("exclusiveStartKey")?false:true} type="dashed" block>
        //   Click here to load more records &nbsp;&nbsp;&nbsp; Loaded records: {currentPageData.length}
        // </Button>
        return  <Row gutter={24}>
                    <Col className="gutter-row" span={4}>
                      <Select
                        onChange={onSelectPageLimit}
                        defaultValue={form.getFieldValue("limit")}
                        value={form.getFieldValue("limit")}
                        placeholder="Page size"
                        style={{ width: "100%" }}
                        options={[
                          { value: 10, label: 10 },
                          { value: 50, label: 50 },
                          { value: 100, label: 100 },
                          { value: 200, label: 200 },
                          { value: 300, label: 300 },
                          { value: 1000, label: 1000 }
                        ]}
                      />
                    </Col>
                    <Col style={{textAlign:"center"}} className="gutter-row" span={12}>
                      Total Loaded Records: {currentPageData.length}
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Button disabled={page<0?true:false}  style={{width:"100%"}} type="dashed" 
                        onClick={onPrevious}><LeftSquareOutlined /> Previous</Button>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Button disabled={form.getFieldValue("exclusiveStartKey")?false:true} style={{width:"100%"}} type="dashed"
                        onClick={onNext}>Next <RightSquareOutlined /></Button>
                    </Col>
                </Row>
      }}></Table>
    </>
  )
}

export default Index