import React,{ useState,useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Button, Table,Popover,Select,Form,Input,Col, DatePicker, Row } from 'antd';
import type { TableColumnsType } from 'antd';
import {httpRequest} from "../../../services/axiosService"
import Moment from 'moment';

import {
  LeftSquareOutlined,
  RightSquareOutlined,
  EyeOutlined,
  SearchOutlined,
  DownloadOutlined,
  SyncOutlined
} from '@ant-design/icons';
export interface process {
  [key: string]: string | undefined
}
interface DataType {
  key: string;
  id: string;
  locale:string;
  action: string;
  flowType: string;
  flowAquestions:any;
  inputWords:any;
  numWordsFreeRecalled:string;
  numWordsCueRecalled:string;
  cueInputWords:any;
  resourceUrl:string;
  surveyUrl:string;
  ad8Questions:string;
  assessmentScore:string;
  version:string;
  updatedAt:string
}
const columns: TableColumnsType<DataType> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    width: "200px",
    fixed: 'left',
  },
  {
    title: 'Language',
    dataIndex: 'locale',
    key: 'locale',
    width: "200px",
    fixed: 'left',
  },
  {
    title: 'Flow Type',
    dataIndex: 'flowType',
    key: 'flowType',
    fixed: 'left',
  },
  {
    title: 'Operation',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'Questions',
    dataIndex: 'flowAquestions',
    render: (obj) => {
      if(typeof obj !== 'object') return ''
      let tab = `<table style='text-align:left'>`
      for (const [key, value] of Object.entries(obj)) {
        tab=tab+`<tr><th>${key}<th><td>${value}<td></tr>`
      }
      tab=tab+`</table>`
      let content=<div dangerouslySetInnerHTML={{__html: tab}}></div>
      return <><Popover content={content} trigger="click">
      <EyeOutlined style={{color:"blue"}}/>
    </Popover></>
    },
    key: 'flowAquestions',
  },
  {
    title: 'QueryString',
    dataIndex: 'querystring',
    render: (obj) => {
      if(typeof obj !== 'object') return ''
      let tab = `<table style='text-align:left'>`
      for (const [key, value] of Object.entries(obj)) {
        tab=tab+`<tr><th>${key}<th><td>${value}<td></tr>`
      }
      tab=tab+`</table>`
      let content=<div dangerouslySetInnerHTML={{__html: tab}}></div>
      return <><Popover content={content} trigger="click">
      <EyeOutlined style={{color:"blue"}}/>
    </Popover></>
    },
    key: 'querystring',
  },
  {
    title: 'Mis UserWords',
    dataIndex: 'inputWords',
    key: 'inputWords',
    render: (obj) => {
      if(!Array.isArray(obj)) return ''
      let tab = `<table style='text-align:left'>`
      obj.forEach((qt:any) => {
        tab=tab+`<tr><th>${qt.validCategory}<th><td>${qt.value}<td></tr>`
      });
      tab=tab+`</table>`
      let content=<div dangerouslySetInnerHTML={{__html: tab}}></div>
      return <><Popover content={content} trigger="click">
      <EyeOutlined style={{color:"blue"}}/>
    </Popover></>
    },
  },
  {
    title: 'MIS freeRecall',
    dataIndex: 'numWordsFreeRecalled',
    key: 'numWordsFreeRecalled',
  },
  {
    title: 'Mis CueRecall',
    dataIndex: 'numWordsCueRecalled',
    key: 'numWordsCueRecalled',
  },
  {
    title: 'Mis CueInputWords',
    dataIndex: 'cueInputWords',
    key: 'cueInputWords',
    render: (obj) => {
      if(!Array.isArray(obj)) return ''
      let tab = `<table style='text-align:left'>`
      obj.forEach((qt:any) => {
        tab=tab+`<tr><th>${qt.validCategory}<th><td>${qt.value}<td></tr>`
      });
      tab=tab+`</table>`
      let content=<div dangerouslySetInnerHTML={{__html: tab}}></div>
      return <><Popover content={content} trigger="click">
      <EyeOutlined style={{color:"blue"}}/>
    </Popover></>
    },
  },
  {
    title: 'Resource url',
    dataIndex: 'resourceUrl',
    key: 'resourceUrl',
  },
  {
    title: 'Survey Url',
    dataIndex: 'surveyUrl',
    key: 'surveyUrl',
  },
  {
    title: 'Ad8 Questions',
    dataIndex: 'ad8Questions',
    key: 'ad8Questions',
    render: (obj) => {
      if(!Array.isArray(obj)) return ''
      let tab = `<table style='text-align:left'>`
      obj.forEach((qt:any) => {
        tab=tab+`<tr><th>Q-${qt.question}<th><td>${qt.value}<td></tr>`
      });
      tab=tab+`</table>`
      let content=<div dangerouslySetInnerHTML={{__html: tab}}></div>
      return <><Popover content={content} trigger="click">
      <EyeOutlined style={{color:"blue"}}/>
    </Popover></>
    },
  },
  {
    title: 'Score',
    dataIndex: 'assessmentScore',
    key: 'assessmentScore',
  },
  {
    title: 'Questionnaire Version',
    dataIndex: 'version',
    key: 'version',
  },
  {
    title: 'Created at',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    fixed: 'right',
    render: (obj) => {
      return <>{Moment(obj).format('DD/MM/YYYY HH:mm:ss')}</>
    },
  }
];
const { RangePicker } = DatePicker;
const pageLimit:any=10
const Index = () => {    
  const [data, setData] = useState<any>([]);
  const [exclusiveStartKey, setExclusiveStartKey] = useState<any>([]);
  const [page, setPage] = useState(-1);
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  form.setFieldsValue({reportType:"go-365"})

  useEffect(() => {
    (async () => {
      form.setFieldsValue({ limit: pageLimit})
      form.setFieldsValue({action:"table"})
      let results = await getContent()
      setData([...results['Items']])
      await lastEvaluatedKey(results['LastEvaluatedKey'])
    })();
  }, [])
  const getContent = async()=>{
    setLoader(true)
    let results = await httpRequest.post(
      process.env.REACT_APP_GET_VISITORS_DEP??"",
      form.getFieldsValue(true)
    )
    setLoader(false)
    return results['body']
  }
  const lastEvaluatedKey=async(lastEvaluatedKey:any)=>{
    if(lastEvaluatedKey){
      form.setFieldsValue({exclusiveStartKey:lastEvaluatedKey})
      const index = exclusiveStartKey.findIndex((item:any) => {
        return item.id == lastEvaluatedKey.id && item.tailoredPage==lastEvaluatedKey.tailoredPage
      })
      if(index===-1){
        setExclusiveStartKey(exclusiveStartKey.concat(lastEvaluatedKey))
        return true
      }
    }else{
      form.setFieldsValue({exclusiveStartKey:""})
    }
  }
  const onNext=async()=>{
    if(form.getFieldValue("exclusiveStartKey")){
      form.setFieldsValue({action:"table"})
      let results = await getContent();
      setData([...results['Items']]);
      await lastEvaluatedKey(results['LastEvaluatedKey'])
      setPage(page+1)
    }else{
      console.log(page)
      console.log("No next page")
    }
  }
  const onPrevious=async()=>{
    if (page<0) {
      console.log("No previous pages")
      return false
    }
    if(exclusiveStartKey[page-1]){
      form.setFieldsValue({exclusiveStartKey:exclusiveStartKey[page-1]})
    }else{
      form.setFieldsValue({exclusiveStartKey:""})
    }
    form.setFieldsValue({action:"table"})
    let results = await getContent();
    setData([...results['Items']]);
    await lastEvaluatedKey(results['LastEvaluatedKey'])
    setPage(page-1)
  }
  const onSearch = async() => {
    if(!form.getFieldsValue(true).filterData) return false
    
    setPage(-1)
    setExclusiveStartKey([...[]])
    form.setFieldsValue({action:"table"})
    form.setFieldsValue({exclusiveStartKey:""})
    //form.setFieldsValue({limit:""})
    let results = await getContent()
    setData([...results['Items']])
    await lastEvaluatedKey(results['LastEvaluatedKey'])
  }
  const onReset = async() => {
    form.resetFields();
    setPage(-1)
    setExclusiveStartKey([...[]])
    form.setFieldsValue({action:"table"})
    form.setFieldsValue({exclusiveStartKey:""})
    form.setFieldsValue({ limit: pageLimit})
    let results = await getContent()
    setData([...results['Items']])
    await lastEvaluatedKey(results['LastEvaluatedKey'])
  };
  const onDownload = async()=>{
    form.setFieldsValue({limit:""})
    form.setFieldsValue({action:"excel"})
    let results = await getContent()
    saveAs.saveAs(results, `${Date.now()}_report.xlsx`)
  }
  const onSelectRagePicker = (date:any)=>{
    if(Array.isArray(date)){
      form.setFieldsValue({['filterData']: 
        { "rangePicker":[date[0].format('YYYY-MM-DD HH:mm:ss'), date[1].format('YYYY-MM-DD HH:mm:ss')]}
      })
    }else{
      form.setFieldsValue({['filterData']: 
        { "rangePicker":[]}
      })
    }
  }
  const onSelectPageLimit = async(limit:any)=>{
    //if(form.getFieldsValue(true).filterData) return false
    
    setPage(-1)
    setExclusiveStartKey([...[]])
    form.setFieldsValue({action:"table"})
    form.setFieldsValue({exclusiveStartKey:""})
    form.setFieldsValue({limit:limit})
    let results = await getContent()
    setData([...results['Items']])
    await lastEvaluatedKey(results['LastEvaluatedKey'])
  }
  return (
    <>
    <Form disabled={loader} onFinish={onSearch} form={form}>
      <Form.List name="filterData">
        {(fields) => (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={4}>
              <Form.Item name="action">
                <Select
                  placeholder="Select operation"
                  style={{ width: "100%" }}
                  options={[
                    { value: '', label: 'All' },
                    { value: 'GET_A', label: 'GET_A' },
                    { value: 'GET_C', label: 'GET_C' },
                    { value: 'GET_AD8', label: 'GET_AD8' },
                    { value: 'SUBMIT_AD8', label: 'SUBMIT_AD8'},
                    { value: 'GET_MIS', label: 'GET_MIS' },
                    { value: 'VERIFY_MIS_WORDS', label: 'VERIFY_MIS_WORDS' },
                    { value: 'RE_VERIFY_MIS_WORDS', label: 'RE_VERIFY_MIS_WORDS'},
                    { value: 'GET_SCORE', label: 'GET_SCORE'}
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="locale">
                <Select
                  placeholder="Language"
                  style={{ width: "100%" }}
                  options={[
                    { value: '', label: 'All' },
                    { value: 'en', label: 'English' },
                    { value: 'es', label: 'Spanish' }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="id">
                <Input placeholder="Id" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="version">
                <Select
                  placeholder="Questionnaire version"
                  style={{ width: "100%" }}
                  options={[
                    { value: '', label: 'All' },
                    { value: 'BrainGuideStandard', label: 'BrainGuideStandard' }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="flowType">
                <Select
                  placeholder="Select flow type"
                  style={{ width: "100%" }}
                  options={[
                    { value: '', label: 'All' },
                    { value: 'a', label: 'Flow-A' },
                    { value: 'ad8', label: 'Flow-Ad8' },
                    { value: 'mis', label: 'Flow-MIS' },
                    { value: 'c', label: 'Flow-C' }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="assessmentScore">
                <Input placeholder="Score" />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form.List>
      <Row style={{ marginTop: 16,marginBottom: 16 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={8}>
          <Form.Item name="rangePicker">
            <RangePicker
              onChange={onSelectRagePicker}
              style={{width:"100%"}}
              showTime={{ format: 'HH:mm' }}
              format="DD/MM/YYYY HH:mm"
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={4}>
          &nbsp;
        </Col>
        <Col className="gutter-row" span={4}>
          <Button style={{width:"100%"}} icon={<SyncOutlined />} onClick={onReset}>Reset</Button>
        </Col>
        <Col style={{display: 'flex', justifyContent: 'flex-end'}} className="gutter-row" span={4}>
          <Button style={{width:"100%"}} icon={<SearchOutlined />}  htmlType="submit">Search</Button>
        </Col>
        <Col className="gutter-row" span={4}>
          <Button style={{width:"100%"}} icon={<DownloadOutlined />} onClick={onDownload}>Download</Button>
        </Col>
      </Row>
    </Form>
    <Table columns={columns} 
      dataSource={data} 
      pagination={false}
      loading={loader}
      rowKey={(record:any,key:any) => {
        return key
      }}
      scroll={{ x: 1500, y: 300 }}
      footer={(currentPageData) => {
        return  <Row gutter={24}>
                    <Col className="gutter-row" span={4}>
                      <Select
                        onChange={onSelectPageLimit}
                        defaultValue={form.getFieldValue("limit")}
                        value={form.getFieldValue("limit")}
                        placeholder="Page size"
                        style={{ width: "100%" }}
                        options={[
                          { value: 10, label: 10 },
                          { value: 50, label: 50 },
                          { value: 100, label: 100 },
                          { value: 200, label: 200 },
                          { value: 300, label: 300 },
                          { value: 1000, label: 1000 }
                        ]}
                      />
                    </Col>
                    <Col style={{textAlign:"center"}} className="gutter-row" span={12}>
                      Total Loaded Records: {currentPageData.length}
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Button disabled={page<0?true:false}  style={{width:"100%"}} type="dashed" 
                        onClick={onPrevious}><LeftSquareOutlined /> Previous</Button>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Button disabled={form.getFieldValue("exclusiveStartKey")?false:true} style={{width:"100%"}} type="dashed"
                        onClick={onNext}>Next <RightSquareOutlined /></Button>
                    </Col>
                </Row>
      }}></Table>
    </>
  )
}

export default Index