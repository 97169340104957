import {Routes,Route,BrowserRouter as Router, RouterProvider, createBrowserRouter } from 'react-router-dom';
import {Login,Dashboard,Visitors,SharedPdf,Setting,NoMatch,
PreventionSharedPdf,PreventionVisitors,FollowUpEmail, Go365Visitors, Go365SharedPdf} from '../pages';
import {PublicRoute} from '../hooks/PublicRoute'
import {PrivateRoute} from '../hooks/PrivateRoute'
import { UserProvider  } from "../contexts/UserContext";

//https://dev.to/sanjayttg/jwt-authentication-in-react-with-react-router-1d03
//https://github.com/sreenathkspanikker/react-private-public-routes
const Routers = () => {
  return (
    <Router>
      <UserProvider>
      <Routes>
        <Route path='/' element={<PrivateRoute/>}>
          <Route path='/' element={<Visitors/>}/>
          {/* <Route path='/dashboard' element={<Dashboard/>}/> */}
          <Route path='/visitors' element={<Visitors/>}/>
          <Route path="/shared-pdf" element={<SharedPdf />} />
          <Route path='/prevention/visitors' element={<PreventionVisitors/>}/>
          <Route path="/prevention/shared-pdf" element={<PreventionSharedPdf />} />
          <Route path="/follow-up-email" element={<FollowUpEmail />} />
          <Route path="/setting" element={<Setting />} />
          <Route path='/go-365/visitors' element={<Go365Visitors/>}/>
          <Route path="/go-365/shared-pdf" element={<Go365SharedPdf/>} />
        </Route>
        <Route path='/' element={<PublicRoute/>}>
          <Route path="/" element={<Login />} />
          <Route path="/sign-in" element={<Login />} />
        </Route>
        <Route path="*" element={<NoMatch/>} />
      </Routes>
      </UserProvider>
    </Router>
  );
};
  
export default Routers;